// .hoverEff::after {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 3px;
//   bottom: -2px;
//   left: 0;
//   background-color: #f59e0b;
//   border-radius: 0.15em;
//   transform: scale(0);
//   transition: transform 0.3s ease;
// }

// .hoverEff:hover::after {
//   transform: scaleX(1);
// }

.active-link {
  border-bottom: 2px solid #bc68b2;
  border-radius: 3px;
  color: #bc68b2;
}
