.line::after {
  content: "";
  position: absolute;
  width: 120%;
  height: 16px;
  transform: rotate(-2deg);
  border-bottom: solid 4px #bc68b2;
  bottom: -6px;
  left: 0;
  border-radius: 50%;
}

.card-center::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #8fddff, #0291ff, #8fddff);
  top: -1px;
  left: 0;
}
