.nav-hover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #26a9e0;
  left: 0;
  bottom: 0;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
  border-radius: 1em;
}

.nav-hover:hover::after {
  transform: scale(1);
}

.active {
  border-bottom: 2px solid #fcd205;
  border-radius: 0.15rem;
}

.slider {
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.cartCounter {
  font-size: 12px;
  height: 2em;
  width: 2em;
  font-weight: 500;
}
