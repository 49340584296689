.hero-wrapper {
  background-image: url("../../shared/images/HEROBACKGROUND.webp");
}

.text {
  font-size: clamp(2.5rem, 2.5rem + 2vw, 4rem);
}

.text-animation {
  background: linear-gradient(
    to right,
    rgb(169, 225, 255),
    #1267a9,
    rgb(169, 225, 255)
  );
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 3s linear infinite alternate;
}

@keyframes gradient {
  0% {
    background-position: 0% 75%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 70%;
  }
}
