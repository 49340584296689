.products-bg {
  background-image: url("../../shared/images/productsBg.webp");
}

.line::after {
  content: "";
  position: absolute;
  width: 120%;
  height: 16px;
  transform: rotate(-2deg);
  border-bottom: solid 4px #bc68b2;
  bottom: -6px;
  left: 0;
  border-radius: 50%;
}
