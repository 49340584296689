.main {
  background: url("../../shared/images/productsBg.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.container {
  border: 1px solid gray;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  max-width: 800px;
  padding: 1.5em;
}

.container h1,
.container h2 {
  font-weight: 600;
  font-size: 1.3em;
}

.containerh h2 {
  margin-top: 20px;
}

.container p {
  margin-bottom: 20px;
}
